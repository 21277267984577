var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("CPF do Cliente")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "form-row form-row-one-col text-center" },
            [
              _c("div", { staticStyle: { "margin-bottom": "3px" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: _vm.masks.cpf,
                      expression: "masks.cpf"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cpf,
                      expression: "cpf"
                    }
                  ],
                  attrs: {
                    id: "cpf",
                    maxlength: "14",
                    name: "cpf",
                    size: "23",
                    type: "text"
                  },
                  domProps: { value: _vm.cpf },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.cpf = $event.target.value
                    }
                  }
                }),
                _c(
                  "a",
                  {
                    staticClass: "btn-azul btn-padrao",
                    attrs: { id: "btn-consultar" },
                    on: { click: _vm.consultar }
                  },
                  [_vm._v("Consultar")]
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }