<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>CPF do Cliente</div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="form-row form-row-one-col text-center">
        <div style="margin-bottom: 3px">
          <input id="cpf" maxlength="14" name="cpf" size="23" type="text" v-mask="masks.cpf" v-model="cpf" />
          <a id="btn-consultar" @click="consultar" class="btn-azul btn-padrao">Consultar</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ROUTERS from "../../../routes/router-constants";
import { mapCliente, mapClienteFields } from "@/store/modules/caixaqui-clientes";
import { mapActions, mapGetters } from "vuex";
import { operationTypes } from "@/store/modules/controller";
import { OPTIONS } from "@/config";

export default {
  name: "CaixaquiCadastroClientesCpfCliente",

  data: () => ({
    masks: OPTIONS.masks
  }),
  computed: {
    ...mapGetters("controller", ["operationType"]),
    ...mapClienteFields(["cpf"]),
    ...mapCliente(["clientes"])
  },
  created() {
    if (this.operationType === operationTypes.CADASTRO_TREINAMENTO && !this.cpf) {
      this.newCliente();
    }
  },
  methods: {
    ...mapActions("caixaquiClientes", ["changeClienteIfExists", "newCliente"]),
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (this.operationType !== operationTypes.CADASTRO_DOSSIE && to) {
        this.$router.push(to);
      }
    },
    consultar() {
      if (this.cpf) {
        if (this.clientes) {
          this.changeClienteIfExists(this.cpf).then(() => {
            this.$router.push({ name: ROUTERS.caixaqui.cadastroClientes.dadosCadastrais.name });
          });
        } else {
          this.$router.push({ name: ROUTERS.caixaqui.cadastroClientes.dadosCadastrais.name });
        }
      } else {
        alert("CPF do cliente inválido.");
      }
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#cpf {
  width: 170px;
  margin-right: 4px;
}
</style>
